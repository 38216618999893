<template>
  <div>
    <slider></slider>
    <online-courses></online-courses>
    <attendance-courses></attendance-courses>
    <course-info-modal></course-info-modal>
    <learning-path></learning-path>
    <course-categories></course-categories>
    <our-partners></our-partners>
  </div>
</template>

<script>
import Slider from "./Components/Slider";
import OnlineCourses from "./Components/OnlineCourses";
import AttendanceCourses from "./Components/AttendanceCourses";
import CourseInfoModal from "../../core/CourseInfoModal/CourseInfoModal";
import LearningPath from "../CourseArea/Pages/LearningPath/LearningPath";
import CourseCategories from "./Components/CourseCategories";
import OurPartners from "./Components/OurPartners";

export default {
  name: "Home",
  components: {
    OurPartners,
    CourseCategories,
    LearningPath,
    OnlineCourses,
    AttendanceCourses,
    CourseInfoModal,
    Slider
  }
};
</script>

<style scoped></style>
