<template>
  <div
    class="uk-inline uk-border-radius"
    style="height: 280px"
    :href="'#modal-courses-1'"
    uk-toggle
    @click="selectCourseModalMethod(item.id)"
  >
    <img :src="getBased(item.thumbnail)" alt="" />
    <div class="uk-overlay uk-overlay-primary uk-position-bottom">
      <p>{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CourseCard",

  data() {
    return {
      img: require("../../../assets/img/ECourses/photo-1457904375453-3e1fc2fc76f4.png")
    };
  },

  props: {
    item: Object
  },

  methods: {
    ...mapActions("CourseInfoModal", ["selectCourseModal"]),
    selectCourseModalMethod(id) {
      this.selectCourseModal(id);
    }
  }
};
</script>

<style scoped>
.ECourses-section .uk-border-radius .uk-overlay-primary,
.ACourses-section .uk-border-radius .uk-overlay-primary {
  border-radius: 30px !important;
}
</style>
