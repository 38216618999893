<template>
  <div>
    <!-- sliders -->
    <section class="heroSlider-sertion">
      <div class="uk-container">
        <div uk-slider="center: true" dir="ltr">
          <div
            class="uk-position-relative uk-visible-toggle uk-light"
            tabindex="-1"
          >
            <ul class="uk-slider-items uk-child-width-1-1 uk-grid uk-slider">
              <li v-for="(slider, index) in sortSliders" :key="index">
                <div
                  class="uk-card uk-card-default uk-grid-collapse uk-flex-middle uk-margin"
                  uk-grid
                >
                  <div
                    class="uk-card-media-left uk-cover-container uk-width-3-3@m uk-border-radius-l uk-clip-path"
                  >
                    <img :src="getBased(slider.image_url)" alt="" uk-cover />
                    <div class="uk-overlay-default uk-position-cover"></div>
                    <canvas width="600" height="400"></canvas>
                  </div>
                </div>
              </li>
            </ul>
            <a
              class="uk-position-center-left uk-position-large"
              href="#"
              uk-slidenav-previous
              uk-slider-item="previous"
            ></a>
            <a
              class="uk-position-center-right uk-position-large"
              href="#"
              uk-slidenav-next
              uk-slider-item="next"
            ></a>
          </div>
          <ul
            class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"
            :class="$i18n.locale == 'en' ? 'en' : ''"
          ></ul>
        </div>
      </div>
    </section>
    <!-- MoveDown -->
    <section class="MoveDown-section">
      <div class="uk-container uk-text-center">
        <a href="#goals" class="scroll-down" uk-scroll
          ><span uk-icon="icon: chevron-down; ratio: 3"></span
        ></a>
      </div>
    </section>
    <!-- golas -->
    <section
      id="goals"
      class="goals-section uk-background-cover uk-text-center"
      :style="goalsBackImg"
    >
      <div class="uk-container">
        <h3>{{ $t("goals.title") }}</h3>
        <div
          class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-padding-child"
          uk-grid
        >
          <div
            class="uk-margin-auto-top@s"
            v-for="(item, index) in goalsInfo"
            :key="index"
          >
            <img :src="item.icon" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Slider",

  data() {
    return {
      goalsBackImg: {
        backgroundImage: `url(${require("../../../assets/img/goals/bg-1.png")}`
      },
      goalsInfo: [
        {
          title: this.$t("goals.learningPrograms"),
          icon: require("../../../assets/img/icon/noun_student.png")
        },
        {
          title: this.$t("goals.certificates"),
          icon: require("../../../assets/img/icon/noun_Certificate.png")
        },
        {
          title: this.$t("goals.onlineLearning"),
          icon: require("../../../assets/img/icon/noun_online.png")
        },
        {
          title: this.$t("goals.offlineAttendence"),
          icon: require("../../../assets/img/icon/noun_training.png")
        }
      ]
    };
  },

  created() {
    this.getSliders();
  },

  methods: {
    ...mapActions("Home", ["getSliders"])
  },

  computed: {
    ...mapGetters("Home", ["sortSliders"])
  }
};
</script>

<style scoped>
.uk-clip-path {
  clip-path: none !important;
  border-radius: 58px !important;
}
.en {
  direction: rtl;
}
</style>
