<!-- BACT-51 -->
<!-- BACT-FIX-DESIGN-1 @AUTH  karam mustafa -->

<template>
  <section class="partners-section">
    <div class="uk-container">
      <div class="uk-text-center uk-margin-large-bottom">
        <h1 class="text-center main-color">{{ $t("ourPartners") }}</h1>
      </div>
      <div uk-slider="center: true">
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
        >
          <ul
            class="uk-slider-items uk-child-width-1-2 uk-child-width-1-2@s uk-child-width-1-4@m uk-grid"
          >
            <li v-for="ourPartner in partners" :key="ourPartner.id">
              <div class="uk-panel">
                <div class="d-flex flex-column justify-center align-center">
                  <img
                    :src="getBased(ourPartner.avatar) || demoImg"
                    :alt="ourPartner.avatar"
                    width="200px"
                    height="200px"
                  />
                  <p class="partner-name">{{ ourPartner.name }}</p>
                </div>
              </div>
            </li>
          </ul>
          <a
            class="uk-position-center-left uk-position-large"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-large"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "OurPartners",

  created() {
    /**
     * @desc getPartners function
     * @author Hamza Sweid
     * @task #BACT-188
     * */
    this.getOurPartners();
  },

  data: function() {
    return {
      demoImg: require("../../../assets/img/LOGO.png")
    };
  },

  methods: {
    ...mapActions("Home", ["getOurPartners"])
  },

  computed: {
    ...mapState("Home", ["partners"])
  }
};
</script>

<style lang="scss">
.partner-name {
  color: var(--primary-color) !important;
  font-size: 19px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.uk-slidenav {
  color: var(--primary-color) !important;
}
</style>
