<!-- bact 37 -->
<!-- BACT-51 -->

<template>
  <div class="learning-path" :class="$i18n.locale == 'en' ? 'en-style' : ''">
    <div class="content-learning-path">
      <h1 class="text-center color-title main-color">
        <b>{{ $t("learningPaths") }}</b>
      </h1>
      <v-row>
        <v-col
          cols="12"
          sm="10"
          md="4"
          lg="5"
          class="learning-path-list uk-margin-auto-right@l"
        >
          <div class="d-flex flex-column justify-start">
            <div
              v-for="path in LearningPath.trainingPaths"
              :key="path.training_path_id"
              class="paths mt-8"
            >
              <span>
                <router-link
                  class="d-flex justify-space-between flex-row"
                  :to="{
                    path:
                      '/training-path-list/' +
                      path.training_path_id
                  }"
                >
                  <span class="float-right main-color">
                    <img width="30" height="30" :src="getBased(path.training_path_thumbnail)">
                    {{ path.training_path_title }}
                  </span>
                  <span class="main-color"> {{ path.courses_count }} {{$t('dashboardMain.pendingCourses.course')}}</span>
                </router-link>
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="10" md="4" lg="5" offset-lg="1">
          <v-img :src="imgSide"></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// BACT-191
import { mapActions, mapState } from "vuex";

export default {
  name: "LearningPath",

  data: function() {
    return {
      imgSide: require("../../../../assets/images/img-side.png")
    };
  },

  created() {
    this.getTrainingPaths();
  },

  methods: {
    ...mapActions("CourseArea", ["getTrainingPaths"]),
    thumbnailPath(path) {
      return `${process.env.VUE_APP_BASE_URL}${path}`;
    }
  },

  computed: {
    ...mapState("CourseArea", ["LearningPath"])
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/learning-path.scss";
@media (max-width: 992px) {
  .learning-path-list {
    padding: 40px;
  }
}
.en-style {
  direction: ltr;
}
</style>
