<template>
  <section id="onlineCourses" class="ECourses-section uk-text-center">
    <div class="uk-container">
      <div class="uk-title-img">
        <h3>{{ $t("onlineCourses") }}</h3>
        <img :src="absIcon" alt="" />
      </div>
      <!-- course card -->
      <div uk-slider="center: false">
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
        >
          <ul
            class="uk-slider-items uk-child-width-1-3@l uk-child-width-1-2@s uk-child-width-1-3@m uk-grid"
          >
            <li v-for="onlineCourse in onlineCourses" :key="onlineCourse.id">
              <course-card :item="onlineCourse"></course-card>
            </li>
          </ul>
          <a
            class="uk-position-center-left uk-position-large"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-large"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CourseCard from "./CourseCard";
import { mapActions, mapState } from "vuex";

export default {
  name: "OnlineCourses",

  components: { CourseCard },

  data() {
    return {
      absIcon: require("../../../assets/img/icon/noun_online_g.png")
    };
  },

  created() {
    this.getOnlineCourses();
  },

  methods: {
    ...mapActions("Home", ["getOnlineCourses"])
  },

  computed: {
    ...mapState("Home", ["onlineCourses"])
  }
};
</script>

<style scoped>
.uk-slider-items {
  direction: ltr;
  display: flex;
  justify-content: center;
}
</style>
